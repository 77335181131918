export enum TABS_OPTIONS {
  BASE_REFERRAL_REWARDS = 'base-referral',
  BUILDER_GENERATIONAL = 'builder-generational',
}

export interface IRewardAnalytics {
  label: string;
  value: string | number;
  unit?: string;
  bigText?: boolean;
  btn?: boolean;
  childs?: any;
}

export type TTotalProduction = {
  title: string;
  value: number;
  tooltipContent: React.ReactNode;
};

export type TRewardAnalytics = IRewardAnalytics[];

export interface UserStatsData {
  title: string;
  value: number;
  blackBG: boolean;
  token: string;
}

export interface IDataPoint {
  x: string;
  y: number | null;
}

export interface IRewardTableDataInterface {
  date: Date;
  type: string;
  totalRewards: string;
  gask: string;
}

export type TFromUser = {
  _id: string;
  email: string;
  blockchainId: string;
  createdAt: string;
  updatedAt: string;
};

export type TToken = {
  _id: string;
  name: string;
  symbol: string;
  type: string;
  withdrawType: string;
  color: string;
  networks: string[];
  iconUrl: string;
  updatedAt: string;
  createdAt: string;
};

export interface ISuperNodeProduction {
  myProduction: number;
  teamProduction: number;
  firstLineProduction: number;
  netTotal: number;
  token: any;
}

export interface ISuperNodeRewardsAnalytics {
  totalRewards: number;
  baseReferralRewards: number;
  builderGenerationalRewards?: number;
  builderReferralRewards: number;
  claimableRewards: number;
  rewardToken: ISuperNodeRewardsToken;
  actualbuilderGenerationalRewards: number;
  actutalbaseReferralRewards: number;
}

export interface IDailySuperNodeRewards {
  totalToken: number;
  tokenAmount: number;
  rewardToken: ISuperNodeRewardsToken;
}

interface ISuperNodeRewardsToken {
  _id: string;
  name: string;
  symbol: string;
  type: string;
  withdrawType: string;
  color: string;
  networks: string[];
  iconUrl: string;
}

export interface ICurrentLossOfRewardsLoss {
  inActiveFirstUser: number;
  dailyCapping: number;
  insufficientGask: number;
  netTotal: number;
}

export interface IActiveFirstLineUserObject {
  _id: string;
  user: IMyTreeUsers;
  upline: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  machineCount: number;
  totalRewards: number;
}

export interface IActiveFirstLineUser {
  totalCount: number;
  list: IActiveFirstLineUserObject[];
  totalPages: number;
  currentPage: number;
}

export interface IHighestPricedMachine {
  allTimeHigh: number;
  autoCompound: boolean;
  boost: number;
  collatoral: number;
  createdAt: string;
  dlp: number;
  endDate: string;
  externalMachineId: string;
  imageUrl: string;
  isSimulater: boolean;
  lockedPrice: number;
  mintingPower: number;
  name: string;
  prevDayPrice: number;
  product: string;
  productPrice: number;
  rewardWallet: string;
  serialNumber: number;
  stakeLimit: number;
  stakeToken: string;
  stakeUnlimited: boolean;
  startDate: string;
  status: string;
  uniqueName: string;
  updatedAt: string;
  user: string;
  _id: string;
}

export interface IMyTreeReward {
  firstLineNode: number;
  totalNodes: number;
  activeFirstLineBuilderGenerationNode?: number;
  activeBuilderGenerationTotalNode?: number;
  activeFirstLineBaseReferralNode?: number;
  activeBaseReferralTotalNode?: number;
}

export interface IMyTree {
  _id: string;
  baseReferralStatus?: boolean;
  builderGenerationStatus?: boolean;
  children: IChildern[];
  production: number;
  reward: number;
  status: boolean;
  updatedAt: string;
  createdAt: string;
  upline: string;
  user: IMyTreeUsers;
}

export interface IChildern extends IMyTree {}

export interface IHeadCells {
  id: string;
  label: string;
  dataClass: string;
}

export interface IMyTreeUsers {
  blockchainId?: string;
  builderReferralBonusEligibility?: boolean;
  createdAt: string;
  dateJoined: string;
  email: string;
  firstName: string;
  isBaseReferralActive?: boolean;
  isBaseReferralEnabled?: boolean;
  isBuilderGeneralEnabled?: boolean;
  isBuilderGenerationActive?: boolean;
  isBuilderGenerationEnabled?: boolean;
  isBuilderReferralEnabled?: boolean;
  isSupernodeActive?: boolean;
  lastLogin?: string;
  lastName?: string;
  profilePicture?: string;
  rewardMultiplier?: number;
  updatedAt: string;
  username?: string;
  _id: string;
}

export interface IUserChartDetail {
  x: string;
  y: number | null;
}

export interface ISuperNodeState {
  allHistory?: {
    totalPages: number;
    list: {
      _id: string;
      user: string;
      fromUser: TFromUser;
      bonusType: string;
      tokenAmount: number;
      totalTokenPrice: number;
      token: TToken;
      createdAt: string;
      updatedAt: string;
    }[];
  };
  isUserLoading: boolean;
  claimRewardLoading: boolean;
  getSuperNodeRewardLoading: boolean;
  rewardTableData: IRewardTableDataInterface[];
  getTotalRewardsLoading: boolean;
  totalRewards: any;
  getCurrentLossOfRewardsLoading: boolean;
  currentLossOfRewardsLoss: ICurrentLossOfRewardsLoss | null;
  rewardChart: IDataPoint[];
  getProductionLoading: boolean;
  superNodeProductData: ISuperNodeProduction | null;
  getRewardAnalyticsLoading: boolean;
  getSngpScoreLoading: boolean;
  superNodeRewardsAnalytics: ISuperNodeRewardsAnalytics | null;
  detailedUser: IMyTreeUsers;
  getUserDailyRewardLoading: boolean;
  getUserChartDetailLoading: boolean;
  myTreeData: IMyTreeReward | null;
  token?: {
    name: string;
    symbol: string;
    iconUrl: string;
  };
  getMyFriendsHistoryLoading: boolean;
  getTotalGaskLoading: boolean;
  getMyTreeLoading: boolean;
  getUserRewardHistoryLoading: boolean;
  userRewardHistory: {
    currentPage: number;
    list: any[];
    totalCount: number;
    totalPages: number;
  };
  userActiveStatus: {
    isActive: boolean;
  } | null;
  totalGask: number | null;
  sngpScoreResponse: any;
  getDailySuperNodeRewardsLoading: boolean;
  dailySuperNodeRewards: IDailySuperNodeRewards | null;
  getActiveFirstLineUsersLoading: boolean;
  activeFirstLineUsers: IActiveFirstLineUser;
  rewardTreeLoading: boolean;
  myTree: IMyTree[] | null;
  userChartDetail: IUserChartDetail[];
  userDailyReward: IUserDailyReward;
  superNodeSuperNodeRewardsLoading: boolean;
  superNodeSuperNodeRewards: ISuperNodeSuperNodeRewards | null;
}

export interface IUserDailyReward {
  totalReward: number;
  dailyRewards: number;
  dailyProduction: number;
  totalProduction: number;
  token: ISuperNodeRewardsToken;
}

export type TListingTree = {
  data: IMyTree;
  view: string;
  isChild?: boolean;
  isPopup?: boolean;
};

export interface TListingTreeNode extends TListingTree {
  randomColor?: string;
  parentId?: string;
  showChildDetails?: () => void;
}

export type TListingMembers = {
  data: IMyTree[];
  view: string;
  isChild?: boolean;
  isPopup?: boolean;
  loading?: boolean;
};

export interface ISuperNodeSuperNodeRewards {
  currentPage: number;
  list: ISuperNodeSuperNodeRewardsList[];
  totalCount: number;
  totalPages: number;
}

export interface ISuperNodeSuperNodeRewardsList {
  _id: string;
  user: string;
  fromUser: ISuperNodeSuperNodeRewardsListFromUser;
  type: string;
  amount: number;
  tokenAmount: number;
  receivable: boolean;
  lostReason: string;
  gaskRemaining: number;
  loss: number;
  claimed: boolean;
  rewardData: ISuperNodeSuperNodeRewardsListRewardData;
  cloudkTrx: string;
  createdAt: string;
  updatedAt: Date;
  token: ISuperNodeRewardsToken;
}

export interface ISuperNodeSuperNodeRewardsListFromUser {
  _id: string;
  email: string;
  blockchainId: string;
  username: string;
  firstName: null | string;
  lastName: null | string;
  profilePicture: null | string;
  createdAt: string;
  updatedAt: string;
  dateJoined: string;
}

export interface ISuperNodeSuperNodeRewardsListRewardData {
  amount: number;
  percentage: number;
  currentLevel: number;
}

export enum SUPERNODE_BONUS_TYPE {
  BASE_REFERRAL = 'base-referral',
  BUILDER_GENERATIONAL = 'builder-generational',
  BUILDER_REFERRAL = 'builder-referral',
  SUPERNODE_GLOBAL_POOL = 'supernode-global-pool',
}
