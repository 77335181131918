import ListingMembers from './ListingMembers';
import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice.ts';

const CommunityCard = () => {
  const { rewardTreeLoading, myTree } = useSelector(selectSuperNodeSlice);

  return (
    <section className="screen1700:max-w-[1600px] screen1400:max-w-[1170px] screen1200:max-w-[1000px] max-h-[30rem] overflow-auto small-scroll">
      <ListingMembers
        data={myTree || []}
        view={'listing'}
        key={1}
        loading={rewardTreeLoading}
      />
    </section>
  );
};

export default CommunityCard;
