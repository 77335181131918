import moment from 'moment';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CardLayout from '../../../components/Cards/CardLayout/index.tsx';
import GetCSVData from '../../../components/GetCSVData.tsx';
import GlobalSelect, {
  IGlobalSelect,
} from '../../../components/Global/GlobalSelect.tsx';
import GlobalTable from '../../../components/Global/GlobalTable.tsx';
import useDebounce from '../../../hooks/useDebounceHook.ts';
import { useSuperNodeActions } from '../../../store/superNode/superNodeActions.ts';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import {
  ISuperNodeSuperNodeRewardsList,
  SUPERNODE_BONUS_TYPE,
} from '../../../types/superNodes.type.ts';
import { formatDateFns } from '../../../utils/helper.ts';
import { toFixedWithoutRoundingOff } from '../../../utils/utils.ts';

const headCells = [
  {
    id: 'date',
    label: 'Date',
    dataClass: 'w-[35%]',
  },
  {
    id: 'from',
    label: 'From User',
    dataClass: 'w-[30%]',
  },
  {
    id: 'level',
    label: 'Bonus Level',
    dataClass: 'w-[15%]',
  },
  {
    id: 'type',
    label: 'Type',
    dataClass: 'w-[30%]',
  },
  {
    id: 'rewards',
    label: 'Total Rewards',
    dataClass: 'w-[30%]',
  },
  {
    id: 'gask',
    label: 'Remaining GASK',
    dataClass: 'w-[25%]',
  },
  {
    id: 'loss',
    label: 'Total Loss',
    dataClass: 'w-[25%]',
  },
];

const RewardsTable = () => {
  const { getRewardsTableData } = useSuperNodeActions();

  const { superNodeSuperNodeRewards, superNodeSuperNodeRewardsLoading } =
    useSelector(selectSuperNodeSlice);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [searchValue, setSearchValue] = React.useState<any>(null);
  const [downloadTransaction, setDownloadTransaction] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [resetFilter, setResetFilter] = useState(false);
  const [dataChanged, setDateChanged] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);

  const { debouncedValue: debouncedSearchValue } = useDebounce(
    searchValue,
    1000
  );
  const [resetDisable, setResetDisable] = useState(true);

  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    type: null,
    fromDate: null,
    toDate: null,
    query: null,
    totalPages: 0,
    totalCount: 0,
  });

  const filterEmptyCheck = () =>
    !filters.query && !filters.type && !filters.fromDate && !filters.toDate;

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));
    const updatedFilters = {
      ...filters,
      fromDate: from,
      toDate: to,
    };
    fetchData(updatedFilters);
  };

  const handleReset = () => {
    setSearchValue('');
    const newFilter = {
      limit: 10,
      page: 1,
      type: '',
      fromDate: null,
      toDate: null,
      query: null,
      totalPages: 0,
      totalCount: 0,
    };
    setFilters({
      newFilter,
    });
    fetchData(newFilter);
    setSelectedStatus('');
    setResetFilter(true);
    setDateChanged(false);
  };

  const superNodesRewardsType: IGlobalSelect[] = [
    {
      name: 'Base Referral',
      value: SUPERNODE_BONUS_TYPE.BASE_REFERRAL,
    },
    {
      name: 'Builder Generational',
      value: SUPERNODE_BONUS_TYPE.BUILDER_GENERATIONAL,
    },
    {
      name: 'Builder Referral',
      value: SUPERNODE_BONUS_TYPE.BUILDER_REFERRAL,
    },
    {
      name: 'Supernode Global Pool',
      value: SUPERNODE_BONUS_TYPE.SUPERNODE_GLOBAL_POOL,
    },
  ];
  const fetchData = async (filters: any) => {
    setResetFilter(false);
    let rewardsResponse;
    rewardsResponse = await getRewardsTableData({
      page: filters?.page,
      limit: 10,
      query: debouncedSearchValue,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
      type: filters?.type,
    });

    if (
      rewardsResponse?.data?.data &&
      rewardsResponse?.data?.data?.list?.length > 0 &&
      resetDisable
    ) {
      setResetDisable(false);
      setTotalPages(rewardsResponse?.data?.data?.totalPages || 0);
      setTotalCount(rewardsResponse?.data?.data?.totalCount || 0);
    }
  };
  // useEffect(
  //   () => {
  //     if (!superNodeSuperNodeRewards?.list.length) {
  //       fetchData(filters);
  //     } else {
  //       if (
  //         superNodeSuperNodeRewards &&
  //         superNodeSuperNodeRewards?.list?.length &&
  //         resetDisable
  //       ) {
  //         setResetDisable(false);
  //         setTotalPages(superNodeSuperNodeRewards?.totalPages || 0);
  //         setTotalCount(superNodeSuperNodeRewards?.totalCount || 0);
  //       }
  //     }

  //     // Reset the flag after making the API call
  //   },
  //   [
  //     // filters,
  //     // filters.fromDate,
  //     // filters.toDate,
  //     // selectedStatus,
  //     // debouncedSearchValue,
  //   ]
  // );

  useEffect(() => {
    if (!superNodeSuperNodeRewardsLoading && !superNodeSuperNodeRewards) {
      fetchData(filters);
    }
  }, []);
  return (
    <CardLayout className="col-span-12 " isBorder={false}>
      <span className="mb-2 text-lg font-medium text-black">
        SuperNode Rewards
      </span>

      <section className="flex flex-col items-start justify-between gap-4">
        <GlobalTable
          rows={
            superNodeSuperNodeRewards &&
            superNodeSuperNodeRewards?.list.length > 0
              ? superNodeSuperNodeRewards?.list?.map(
                  (item: ISuperNodeSuperNodeRewardsList) => {
                    const {
                      createdAt,
                      type,
                      gaskRemaining,
                      loss,
                      tokenAmount,
                      token,
                      rewardData,
                      fromUser,
                    } = item;

                    return {
                      date: (
                        <p className="text-xs font-normal text-primaryGrey">
                          {formatDateFns(createdAt, 'yyyy-MM-dd HH:mm') || '-'}
                        </p>
                      ),
                      from: (
                        <p className="text-xs font-normal text-black">
                          {fromUser?.email || fromUser?.username || ''}
                        </p>
                      ),
                      level: (
                        <p className="text-xs font-normal text-black">
                          {rewardData?.currentLevel || '-'}
                        </p>
                      ),

                      type: (
                        <p className="text-xs font-normal text-black">
                          {type || '-'}
                        </p>
                      ),
                      rewards: (
                        <p className="text-xs font-normal text-black">
                          {toFixedWithoutRoundingOff(tokenAmount, 6) || 0}{' '}
                          {token?.name}
                        </p>
                      ),
                      gask: (
                        <p className="text-xs font-normal text-black">
                          {gaskRemaining ? gaskRemaining.toFixed(6) : 0}
                        </p>
                      ),
                      loss: (
                        <p className="text-xs font-normal text-black">
                          {loss.toFixed(6) || 0}
                        </p>
                      ),
                    };
                  }
                )
              : []
          }
          tableContainerClassname="!rounded-md"
          headCells={headCells}
          searchIsLoading={false}
          resetDateFilter={resetFilter}
          paginationPosition={isMobile ? 'left' : 'right'}
          page={filters?.page}
          totalPages={totalPages}
          handlePageChange={(page) => {
            const updatedFilters = { ...filters, page };
            setFilters(updatedFilters);
            fetchData(updatedFilters);
          }}
          copyItems={['destination']}
          handleDateDate={handleDateDate}
          emptyDataMessage="No Rewards History!"
          setSearchValue={(query: string) => {
            const updatedFilters = { ...filters, query };
            setSearchValue(query ?? null);
            setFilters(updatedFilters);
            setTimeout(() => {
              fetchData(updatedFilters);
            }, 1000);
          }}
          searchValue={searchValue}
          showTotalRecords={false}
          searchFieldPlaceholderText="Search"
          toolTipItems={['remarks']}
          colorItems={['status']}
          dateArrowAllow={true}
          tableDropdowns={
            <div className="flex flex-row space-x-3 sm:items-center">
              <GlobalSelect
                name="type"
                placeholder="Type"
                isError={false}
                value={selectedStatus}
                getSelectedValue={(type: string) => {
                  setFilters((prev: any) => {
                    const updatedFilter = { ...prev, type };
                    fetchData(updatedFilter);
                    return updatedFilter;
                  });
                }}
                options={superNodesRewardsType}
                className={'!rounded-md !min-w-24 !w-auto'}
                classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
                optionsWidth="w-max"
                selectdisable={resetDisable}
              />
              <button
                className={`${filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                type="button"
                onClick={handleReset}
                disabled={filterEmptyCheck()}
              >
                Reset
              </button>
            </div>
          }
          headStyles="h-[2.5rem] !font-[400] md:!text-[11px] xxs:!text-[12px] text-primaryGrey"
          bodyStyles="flex h-[3.5rem]"
          DownloadCSV={
            <GetCSVData
              fileName="rewards.csv"
              disabled={totalPages <= 0}
              transactionData={downloadTransaction}
              onclick={() => {
                getRewardsTableData({
                  page: filters?.page,
                  limit: totalCount,
                  query: debouncedSearchValue,
                  fromDate: filters?.fromDate,
                  toDate: filters?.toDate,
                  type: filters?.selectedStatus,
                }).then((response) => {
                  const list = response?.data?.data?.list;
                  if (list) {
                    let csvData = list?.map((item: any) => {
                      const {
                        createdAt,
                        type,
                        gaskRemaining,
                        loss,
                        tokenAmount,
                        token,
                        rewardData,
                        fromUser,
                      } = item;
                      return {
                        Date:
                          formatDateFns(createdAt, 'yyyy-MM-dd HH:mm') || '-',
                        From: fromUser?.username || fromUser?.email,
                        Level: rewardData?.currentLevel || '-',
                        Type: type || '-',
                        Rewards: `${toFixedWithoutRoundingOff(tokenAmount, 6) || 0} ${token?.name}`,
                        GASK: gaskRemaining.toFixed(6) || 0,
                        Loss: loss.toFixed(6) || 0,
                      };
                    });
                    setDownloadTransaction(csvData);
                  }
                });
              }}
              onDownloadReady={() => {
                // Callback function to handle any additional logic if needed
              }}
            />
          }
          isLoading={
            superNodeSuperNodeRewards?.list?.length === 0 &&
            superNodeSuperNodeRewardsLoading
          }
          tableWidth={'w-full'}
          searchdisable={resetDisable}
          filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
          fromDateDisable={resetDisable}
          toDateDisable={resetDisable}
          fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
          toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
          setDateChanged={setDateChanged}
        />
      </section>
    </CardLayout>
  );
};

export default RewardsTable;
